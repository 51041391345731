// src/components/LoadingScreen.js
import React from "react";

function LoadingScreen() {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col items-center">
        <div
          className="animate-spin inline-block w-8 h-8 border-4 border-t-transparent border-blue-500 rounded-full"
          role="status"
        ></div>
        <span className="mt-2 text-blue-500">Loading...</span>
      </div>
    </div>
  );
}

export default LoadingScreen;
