import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from "../../assets/images/LogoVertical.png";
import { FaSpinner } from "react-icons/fa";
import { jwtDecode } from "jwt-decode"; // Importar jwt-decode correctamente

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // Estado para manejar el error
  const navigate = useNavigate();

  console.log("Version 1.0.2");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(""); // Limpiar cualquier error previo
    const url = "https://0vv4pv4ppf.execute-api.eu-north-1.amazonaws.com/login";

    try {
      const response = await axios.post(
        url,
        { username, password },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        const { token } = response.data;
        try {
          const decodedToken = jwtDecode(token);
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("numCalendars", decodedToken.numCalendars); // Guardar el número de calendarios
          localStorage.setItem("username", decodedToken.username); // Guardar el nombre de usuario
          localStorage.setItem("userPhoneID", decodedToken.userPhoneID); // Guardar el ID del teléfono del usuario
          if (decodedToken.isConfigured) {
            navigate("/app");
          } else {
            navigate("/config");
          }
        } catch (error) {
          console.error("Error decoding token:", error);
          setError("Error interno, por favor intenta de nuevo más tarde.");
        }
      } else {
        console.log("Failed to log in with status code:", response.status);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError(
          "Credenciales incorrectas. Por favor, verifica tu usuario y contraseña."
        );
      } else {
        setError(
          "Ocurrió un error durante el intento de inicio de sesión. Por favor, intenta de nuevo."
        );
      }
      console.error(
        "Error during login attempt:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-bluePalette-lightest">
      <div className="w-1/4 p-8 bg-white shadow-lg rounded-lg flex flex-col items-center">
        <div className="mb-5">
          <img src={Logo} alt="Logo" className="w-24" />
        </div>
        <h2 className="text-center text-black mb-5">
          Iniciar sesión en tu cuenta
        </h2>
        <form onSubmit={handleSubmit} className="w-full">
          {error && (
            <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
              {error}
            </div>
          )}
          <div className="mb-5">
            <label htmlFor="username" className="block mb-2 text-black">
              Nombre de usuario o correo electrónico
            </label>
            <input
              id="username"
              name="username"
              type="text"
              required
              placeholder="Ingresa tu nombre de usuario o correo"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-2 border border-bluePalette-dark rounded focus:outline-none focus:ring-2 focus:ring-bluePalette-dark"
            />
          </div>
          <div className="mb-5">
            <label htmlFor="password" className="block mb-2 text-black">
              Contraseña
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              placeholder="Ingresa tu contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border border-bluePalette-dark rounded focus:outline-none focus:ring-2 focus:ring-bluePalette-dark"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full p-3 bg-bluePalette-dark text-white rounded hover:bg-bluePalette-darker transition duration-300 flex items-center justify-center"
          >
            {loading ? (
              <FaSpinner className="spinner animate-spin" />
            ) : (
              "Acceder"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
