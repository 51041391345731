import React from "react";
import Config from "../components/Calendar/Config";

function CalendarSetupPage() {
  return (
    <div className="calendar-setup-page">
      <Config />
    </div>
  );
}

export default CalendarSetupPage;
