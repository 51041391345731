// src/components/Metrics.js
import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

function Metrics({ onClose, metrics }) {
  // Datos para el gráfico de Pie
  const pieData = [
    { name: "Chats Iniciados", value: metrics.chatsIniciados },
    { name: "Reservas Confirmadas", value: metrics.totalReservas },
    { name: "Cancelaciones", value: metrics.reservationCancellations },
  ];

  // Datos para el gráfico de Barras
  const barData = metrics.weeklyData || [
    {
      week: "Semana 1",
      chatsIniciados: metrics.chatsIniciados,
      totalReservas: metrics.totalReservas,
      reservationCancellations: metrics.reservationCancellations,
    },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto">
      <div className="bg-white p-6 rounded shadow-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl mb-4 text-center">Estadísticas del Negocio</h2>

        {/* Gráfico de Pie */}
        <div className="mb-6">
          <h3 className="text-lg mb-2 text-center">Distribución de Métricas</h3>
          <ResponsiveContainer width="100%" height={250}>
            <PieChart>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) =>
                  `${name} (${(percent * 100).toFixed(0)}%)`
                }
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Tabla Resumen de Métricas */}
        <div className="mb-6">
          <h3 className="text-lg mb-2 text-center">Resumen de Métricas</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Métrica</th>
                  <th className="py-2 px-4 border-b">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2 px-4 border-b">Chats Iniciados</td>
                  <td className="py-2 px-4 border-b">
                    {metrics.chatsIniciados}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-4 border-b">Reservas Confirmadas</td>
                  <td className="py-2 px-4 border-b">
                    {metrics.totalReservas}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-4 border-b">Cancelaciones</td>
                  <td className="py-2 px-4 border-b">
                    {metrics.reservationCancellations}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Gráfico de Barras Apiladas */}
        <div className="mb-6">
          <h3 className="text-lg mb-2 text-center">Evolución Semanal</h3>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={barData}>
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="chatsIniciados" stackId="a" fill="#0088FE" />
              <Bar dataKey="totalReservas" stackId="a" fill="#00C49F" />
              <Bar
                dataKey="reservationCancellations"
                stackId="a"
                fill="#FFBB28"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-red-500 text-white p-2 rounded hover:bg-red-600 transition duration-300"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
}

export default Metrics;
