// /src/components/Chat/ChatList.js
import React from "react";
import SearchChat from "./SearchChat";
import logo from "../../assets/images/LogoBBUKZblanco.png";
import { FaUsers, FaChartBar, FaInbox, FaCog } from "react-icons/fa"; // Importar los iconos necesarios

function ChatList({
  chats,
  onChatSelect,
  selectedChat,
  onOpenMetrics,
  onShowClientStatus,
  onOpenSuggestions,
  onOpenSettings,
}) {
  const hasDeactivatedClients = chats.some((chat) => !chat.activo);

  // Ordenar los chats por el mensaje más reciente
  const sortedChats = [...chats].sort((a, b) => {
    const aLastMessageTime = a.messages.length
      ? a.messages[a.messages.length - 1].timestamp
      : 0;
    const bLastMessageTime = b.messages.length
      ? b.messages[b.messages.length - 1].timestamp
      : 0;
    return bLastMessageTime - aLastMessageTime;
  });

  return (
    <div className="flex flex-col w-full h-full bg-gray-200 border-r border-gray-300">
      <div className="flex justify-center items-center h-24 w-full bg-bluePalette-light">
        <img src={logo} alt="Logo" className="h-20 w-5/6" />
      </div>

      <div className="p-2 border-b border-gray-300 bg-white">
        <button
          className="bg-bluePalette-dark text-white p-2 rounded w-full mb-2 flex items-center justify-center"
          onClick={onOpenMetrics}
        >
          <FaChartBar className="mr-2" />
          Métricas
        </button>
        <div className="relative pb-3">
          <button
            className="bg-bluePalette-dark text-white p-2 rounded w-full flex items-center justify-center"
            onClick={onShowClientStatus}
          >
            <FaUsers className="mr-2 " />
            Lista de Usuarios
          </button>
          {hasDeactivatedClients && (
            <div className="absolute top-2 right-0 h-6 w-6 bg-orange-500 rounded-full flex items-center justify-center">
              <span className="tooltip">
                ⚠
                <span className="tooltiptext">
                  El chatbot está desactivado para al menos un cliente.
                </span>
              </span>
            </div>
          )}
        </div>

        {/* Botones de Sugerencias y Ajustes */}
        <div className="flex space-x-2 mb-3">
          <button
            className="bg-bluePalette-dark text-white p-2 rounded flex-1 flex items-center justify-center"
            onClick={onOpenSuggestions}
          >
            <FaInbox className="mr-2" />
            Sugerencias
          </button>
          <button
            className="bg-bluePalette-dark text-white p-2 rounded flex-1 flex items-center justify-center"
            onClick={onOpenSettings}
          >
            <FaCog className="mr-2" />
            Ajustes
          </button>
        </div>

        <SearchChat chats={chats} onChatSelect={onChatSelect} />
      </div>

      <div className="overflow-y-auto flex-grow bg-white">
        {sortedChats.map((chat) => (
          <div
            key={chat.cliente_tfn}
            onClick={() => onChatSelect(chat)}
            className={`p-4 text-lg cursor-pointer transition ease-in-out ${
              selectedChat?.thread_id === chat.thread_id
                ? "bg-bluePalette-dark text-white"
                : "bg-white hover:bg-gray-100"
            }`}
          >
            <div className="font-bold truncate">{chat.nombre}</div>
            <div className="text-sm truncate">
              {chat.cliente_tfn_sin_prefijo}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChatList;
