// /src/components/Chat/MessageList.js:
import React, { useEffect, useRef } from "react";
import Message from "./Message";
import DateBubble from "./DateBubble";
import moment from "moment";

function MessageList({ messages }) {
  const messageEndRef = useRef(null);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const groupedMessages = messages.reduce((acc, msg) => {
    const date = moment(msg.timestamp).format("DD-MM-YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(msg);
    return acc;
  }, {});

  const sortedDates = Object.keys(groupedMessages).sort();

  return (
    <div className="flex flex-col p-4 space-y-2 overflow-y-auto">
      {sortedDates.map((date, index) => (
        <div key={index}>
          <DateBubble date={date} />
          {groupedMessages[date].map((msg, msgIndex) => (
            <Message
              key={msgIndex}
              sender={msg.sender}
              message={msg.message}
              timestamp={msg.timestamp}
            />
          ))}
        </div>
      ))}
      <div ref={messageEndRef} />
    </div>
  );
}

export default MessageList;
