// src/components/Chat/ClientStatusModal.js
import React from "react";

function ClientStatusModal({ clients, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg w-full max-w-3xl">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Lista de Usuarios
        </h2>
        <div className="overflow-y-auto max-h-96">
          <table className="min-w-full bg-white border">
            <thead>
              <tr className="bg-bluePalette-light text-white">
                <th className="py-3 px-4 text-left">Nombre</th>
                <th className="py-3 px-4 text-left">Teléfono</th>
                <th className="py-3 px-4 text-left">Estado</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((client) => (
                <tr
                  key={client.thread_id}
                  className="border-b hover:bg-gray-100 transition duration-150"
                >
                  <td className="py-3 px-4">{client.nombre || "Sin nombre"}</td>
                  <td className="py-3 px-4">
                    {client.cliente_tfn_sin_prefijo}
                  </td>
                  <td className="py-3 px-4">
                    {client.activo ? (
                      <span className="text-green-600 font-semibold">
                        Activo
                      </span>
                    ) : (
                      <span className="text-red-600 font-semibold">
                        Inactivo
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-6">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClientStatusModal;
