// /src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import MainAppPage from "./pages/MainAppPage";
import CalendarSetupPage from "./pages/CalendarSetupPage";
import { jwtDecode } from "jwt-decode";

function ProtectedRoute({ element }) {
  const location = useLocation();
  const token = localStorage.getItem("jwtToken");

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        localStorage.removeItem("jwtToken");
        return <Navigate to="/login" state={{ from: location }} />;
      }

      return element;
    } catch (error) {
      localStorage.removeItem("jwtToken");
      return <Navigate to="/login" state={{ from: location }} />;
    }
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
}

function App() {
  return (
    <Router>
      <div className="h-full">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/config"
            element={<ProtectedRoute element={<CalendarSetupPage />} />}
          />
          <Route
            path="/app/*"
            element={<ProtectedRoute element={<MainAppPage />} />}
          />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="*" element={<Navigate to="/login" />} />{" "}
          {/* Ruta catch-all para redirigir al login */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
