// /src/components/Chat/SearchChat.js:
import React, { useState } from "react";

function SearchChat({ chats, onChatSelect }) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredChats = searchTerm
    ? chats.filter((chat) => {
        const nombre = chat.nombre || "";
        const cliente_tfn_sin_prefijo = chat.cliente_tfn_sin_prefijo || "";
        return (
          nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
          cliente_tfn_sin_prefijo.includes(searchTerm)
        );
      })
    : [];

  return (
    <div className="p-2 bg-bluePalette-lightest">
      <div className="flex items-center space-x-2">
        <input
          type="text"
          placeholder="Buscar chat..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 w-full rounded border border-gray-400"
        />
      </div>
      <div className="mt-2">
        {filteredChats.map((chat, index) => (
          <div
            key={chat.cliente_tfn}
            onClick={() => onChatSelect(chat)}
            className={`p-2 cursor-pointer text-black ${
              index % 2 === 0
                ? "bg-bluePalette-lightest"
                : "bg-bluePalette-lighter"
            }`}
          >
            <div className="font-bold truncate">{chat.nombre}</div>
            <div className="text-sm hover:bg-bluePalette-lighter text-gray-700 truncate">
              {chat.cliente_tfn_sin_prefijo}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SearchChat;
