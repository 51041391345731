// /src/components/Chat/Message.js:
import React from "react";
import moment from "moment";

// Función para aplicar estilos de texto similares a WhatsApp
function parseMessage(message) {
  const boldPattern = /\*(.*?)\*/g; // Para *texto* en negrita
  const italicPattern = /_(.*?)_/g; // Para _texto_ en cursiva
  const emojiPattern = /(:\w+:)/g; // Para emojis si decides agregar soporte

  // Reemplaza los patrones en el mensaje
  let parsedMessage = message
    .replace(boldPattern, "<strong>$1</strong>")
    .replace(italicPattern, "<em>$1</em>")
    .replace(emojiPattern, (match) => {
      // Aquí podrías agregar soporte para emojis, por ejemplo usando una biblioteca
      return match; // Solo un ejemplo simple, podrías reemplazar con un componente Emoji
    });

  return parsedMessage;
}

function Message({ sender, message, timestamp }) {
  // Convertir timestamp a formato HH:MM
  const formattedTimestamp = moment(timestamp).format("HH:mm");

  // Procesar el mensaje para aplicar los estilos
  const parsedMessage = parseMessage(message);

  return (
    <div
      className={`flex ${
        sender === "sistema" ? "justify-end" : "justify-start"
      } mb-2`}
    >
      <div
        className={`message max-w-md ${
          sender === "sistema"
            ? "bg-bluePalette-light text-white"
            : "bg-bluePalette-lighter text-black"
        }  p-2 rounded relative`}
      >
        <div
          className="whitespace-pre-wrap"
          dangerouslySetInnerHTML={{ __html: parsedMessage }}
        />
        <div
          className={`text-xs ${
            sender === "sistema" ? "text-gray-100" : "text-gray-700"
          }`}
        >
          {formattedTimestamp}
        </div>
      </div>
    </div>
  );
}

export default Message;
