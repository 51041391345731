// /src/components/Chat/DateBubble.js:
import React from "react";

function DateBubble({ date }) {
  return (
    <div className="text-center my-2">
      <span className="bg-gray-300 text-gray-700 py-1 px-2 rounded-full text-sm">
        {date}
      </span>
    </div>
  );
}

export default DateBubble;
