import React from "react";

function CalendarPreviewStep({ calendars, onEdit, onConfirm }) {
  const days = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md flex flex-col max-h-[90vh]">
      <h2 className="text-2xl font-bold mb-4">Vista Previa de Configuración</h2>

      {/* Contenido desplazable */}
      <div className="overflow-y-auto flex-grow mb-4 max-h-[70vh] scrollbar-thin scrollbar-thumb-bluePalette-dark scrollbar-track-bluePalette-lightest">
        {calendars.map((calendar, index) => (
          <div key={index} className="mb-8 p-4 border rounded">
            <h3 className="text-xl font-semibold mb-4">
              Calendario {index + 1}: {calendar.name}
            </h3>
            <div className="mb-4">
              <strong>Duración de Slots:</strong> {calendar.slotDuration}{" "}
              minutos
            </div>
            <div>
              <h4 className="font-semibold mb-2">
                Días y Horarios Disponibles
              </h4>
              {days.map((day) => (
                <div key={day} className="mb-4">
                  <strong>{day}:</strong>
                  {calendar.availableDays[day] ? (
                    <ul className="ml-6 list-disc">
                      {calendar.availableDays[day].map((range, rangeIndex) => (
                        <li key={rangeIndex}>
                          {range.start} - {range.end}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <span className="ml-2 text-gray-500">No disponible</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Contenedor de botones fijo */}
      <div className="py-4 bg-white flex justify-between items-center border-t border-gray-300 sticky bottom-0">
        <button
          onClick={onEdit}
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Editar
        </button>
        <button
          onClick={onConfirm}
          className="px-4 py-2 bg-bluePalette-dark text-white rounded hover:bg-bluePalette-darker"
        >
          Confirmar y Guardar
        </button>
      </div>
    </div>
  );
}

export default CalendarPreviewStep;
