// /src/pages/MainAppPage.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import ChatList from "../components/Chat/ChatList";
import ChatHeader from "../components/Chat/ChatHeader";
import MessageList from "../components/Chat/MessageList";
import SendForm from "../components/Chat/SendForm";
import Metrics from "../components/Chat/Metrics";
import ClientStatusModal from "../components/Chat/ClientStatusModal"; // Importar el nuevo componente
import { GiConsoleController } from "react-icons/gi";
import { FaEnvelope, FaCog } from "react-icons/fa"; // Importar los iconos

function MainAppPage() {
  const [chats, setChats] = useState([]);
  const chatsRef = useRef(chats);
  const [selectedChat, setSelectedChat] = useState(null);
  const [metrics, setMetrics] = useState({
    totalReservas: 0,
    chatsIniciados: 0,
    reservationCancellations: 0,
  });
  const [showMetrics, setShowMetrics] = useState(false);
  const [socket, setSocket] = useState(null);
  const [showAddNameModal, setShowAddNameModal] = useState(false);
  const [showClientStatusModal, setShowClientStatusModal] = useState(false); // Nuevo estado
  const [showSuggestionForm, setShowSuggestionForm] = useState(false); // Estado para mostrar el formulario de sugerencias
  const [suggestionText, setSuggestionText] = useState(""); // Estado para el texto de la sugerencia
  const [showSettingsModal, setShowSettingsModal] = useState(false); // Estado para mostrar el cuadro de ajustes
  const [clientName, setClientName] = useState("");
  const reconnectIntervalRef = useRef(null);
  const token = localStorage.getItem("jwtToken");

  const wsUrl = process.env.REACT_APP_WS;
  const chatsUrl = process.env.REACT_APP_CHATS;
  const metricsUrl = process.env.REACT_APP_METRICS;
  const sendMessageUrl = process.env.REACT_APP_SENDMESSAGE;
  const addClientUrl = process.env.REACT_APP_AGREGARCLIENTE;
  const toggleChatbotUrl = process.env.REACT_APP_TOGLECHATBOT;
  const suggestionsUrl = process.env.REACT_APP_SUGGESTIONS; // URL del API Gateway para sugerencias

  useEffect(() => {
    chatsRef.current = chats;
  }, [chats]);

  const connectWebSocket = () => {
    const ws = new WebSocket(`${wsUrl}?token=${token}`);

    ws.onerror = (event) => {
      console.error("WebSocket error observed:", event);
    };

    ws.onopen = () => {
      console.log("Connected to WebSocket server");
      if (reconnectIntervalRef.current) {
        clearInterval(reconnectIntervalRef.current);
        reconnectIntervalRef.current = null;
      }
    };

    ws.onmessage = async (event) => {
      console.log("WebSocket message received:", event.data);

      const { type, thread_id } = JSON.parse(event.data);
      console.log("Message type:", type, "Thread ID:", thread_id);

      switch (type) {
        case "newMessage":
          console.log("Handling new message for thread:", thread_id);
          try {
            console.log(`Fetching new messages for thread ${thread_id}`);
            const response = await axios.get(
              `${chatsUrl}?thread_id=${thread_id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            const nuevosMensajes = response.data;
            console.log("New messages fetched:", nuevosMensajes);

            // Check if the chat exists
            const chatExists = chatsRef.current.some(
              (chat) => chat.thread_id === thread_id
            );

            if (chatExists) {
              // Update existing chat
              setChats((prevChats) => {
                console.log("Previous chats:", prevChats);
                const updatedChats = prevChats.map((chat) => {
                  if (chat.thread_id === thread_id) {
                    console.log(`Updating chat ${thread_id} with new messages`);
                    return { ...chat, messages: nuevosMensajes };
                  }
                  return chat;
                });

                console.log("Updated chats before sorting:", updatedChats);

                // Reordenar los chats
                const sortedChats = updatedChats.sort((a, b) => {
                  const aLastMessageTime = a.messages.length
                    ? new Date(
                        a.messages[a.messages.length - 1].timestamp
                      ).getTime()
                    : 0;
                  const bLastMessageTime = b.messages.length
                    ? new Date(
                        b.messages[b.messages.length - 1].timestamp
                      ).getTime()
                    : 0;
                  return bLastMessageTime - aLastMessageTime;
                });

                console.log("Sorted chats:", sortedChats);
                return sortedChats;
              });

              // Actualizar el chat seleccionado si es el que recibió el nuevo mensaje
              setSelectedChat((prevChat) => {
                if (prevChat?.thread_id === thread_id) {
                  console.log("Updating selected chat with new messages");
                  return { ...prevChat, messages: nuevosMensajes };
                }
                console.log("Selected chat unchanged");
                return prevChat;
              });
            } else {
              // Chat doesn't exist, fetch the updated list of chats
              console.log(
                `Chat with thread_id ${thread_id} does not exist, fetching chats`
              );
              await fetchChats();
            }
          } catch (error) {
            console.error("Error fetching new messages:", error);
          }
          break;

        case "newCliente":
          console.log("Handling new client");
          fetchChats();
          break;

        case "newMetric":
          console.log("Handling new metric");
          fetchMetrics();
          break;

        default:
          console.log("Received unknown event type:", type);
      }
    };

    ws.onclose = () => {
      console.log(
        "Disconnected from WebSocket server, attempting to reconnect..."
      );
      if (!reconnectIntervalRef.current) {
        reconnectIntervalRef.current = setInterval(() => {
          console.log("Reconnecting to WebSocket server...");
          connectWebSocket();
        }, 5000);
      }
    };

    setSocket(ws);
  };

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (socket) {
        console.log("Closing WebSocket connection");
        socket.close();
      }
      if (reconnectIntervalRef.current) {
        clearInterval(reconnectIntervalRef.current);
      }
    };
  }, []);

  const fetchChats = async () => {
    console.log("Fetching chats...");
    try {
      const result = await axios.get(chatsUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Ordenar los chats por el mensaje más reciente
      const sortedChats = result.data.sort((a, b) => {
        const aLastMessageTime = a.messages.length
          ? a.messages[a.messages.length - 1].timestamp
          : 0;
        const bLastMessageTime = b.messages.length
          ? b.messages[b.messages.length - 1].timestamp
          : 0;
        return bLastMessageTime - aLastMessageTime;
      });

      console.log("Sorted chats:", sortedChats);

      setChats(sortedChats);

      // Asegurarse de que selectedChat sigue siendo válido
      if (selectedChat) {
        const updatedSelectedChat = sortedChats.find(
          (chat) => chat.thread_id === selectedChat.thread_id
        );
        console.log("Updated selected chat:", updatedSelectedChat);
        setSelectedChat(updatedSelectedChat || sortedChats[0]);
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);

  const handleChatSelect = async (chat) => {
    console.log("Selecting chat:", chat.thread_id);
    try {
      const response = await axios.get(
        `${chatsUrl}?thread_id=${chat.thread_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const mensajesActualizados = response.data;
      console.log("Messages for selected chat:", mensajesActualizados);

      // Actualizar el chat seleccionado con los mensajes más recientes
      setSelectedChat({
        ...chat,
        messages: mensajesActualizados,
      });

      // Actualizar la lista de chats con los mensajes más recientes
      setChats((prevChats) => {
        const chatIndex = prevChats.findIndex(
          (ch) => ch.thread_id === chat.thread_id
        );

        if (chatIndex !== -1) {
          const updatedChats = [...prevChats];
          const updatedChat = { ...updatedChats[chatIndex] };

          updatedChat.messages = mensajesActualizados;
          updatedChats[chatIndex] = updatedChat;

          // Reordenar los chats
          return updatedChats.sort((a, b) => {
            const aLastMessageTime = a.messages.length
              ? a.messages[a.messages.length - 1].timestamp
              : 0;
            const bLastMessageTime = b.messages.length
              ? b.messages[b.messages.length - 1].timestamp
              : 0;
            return bLastMessageTime - aLastMessageTime;
          });
        }
        return prevChats;
      });
    } catch (error) {
      console.error("Error fetching messages for selected chat:", error);
    }
  };

  const fetchMetrics = async () => {
    const response = await axios.get(metricsUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("Metrics:", response.data);
    setMetrics(response.data);
  };

  useEffect(() => {
    fetchMetrics();
  }, []);

  const handleSend = async (message) => {
    if (!selectedChat) return;

    const lastMessageTimestamp = selectedChat.messages.length
      ? selectedChat.messages[selectedChat.messages.length - 1].timestamp
      : null;

    const now = moment.utc();

    if (lastMessageTimestamp) {
      const lastMessageTime = moment.utc(lastMessageTimestamp);
      const hoursSinceLastMessage = now.diff(lastMessageTime, "hours");

      if (hoursSinceLastMessage >= 24) {
        alert(
          "No puedes enviar un mensaje porque han pasado más de 24 horas desde la última interacción."
        );
        return;
      }
    }

    try {
      const payload = {
        userPhoneID: selectedChat.userPhoneID,
        from: selectedChat.cliente_tfn,
        message,
        thread_id: selectedChat.thread_id,
      };
      console.log("Sending message:", payload);

      const response = await axios.post(
        `${sendMessageUrl}?token=${token}`,
        payload
      );

      console.log("Message sent:", response.data);

      // Actualizar el chat con el nuevo mensaje
      const newMessage = {
        sender: "sistema",
        message,
        timestamp: moment.utc().format("YYYY-MM-DDTHH:mm:ssZ"),
      };

      setChats((prevChats) => {
        const chatIndex = prevChats.findIndex(
          (chat) => chat.thread_id === selectedChat.thread_id
        );

        if (chatIndex !== -1) {
          const updatedChats = [...prevChats];
          const updatedChat = { ...updatedChats[chatIndex] };

          updatedChat.messages = [...updatedChat.messages, newMessage];
          updatedChats[chatIndex] = updatedChat;

          // Reordenar después de agregar el nuevo mensaje
          return updatedChats.sort((a, b) => {
            const aLastMessageTime = a.messages.length
              ? a.messages[a.messages.length - 1].timestamp
              : 0;
            const bLastMessageTime = b.messages.length
              ? b.messages[b.messages.length - 1].timestamp
              : 0;
            return bLastMessageTime - aLastMessageTime;
          });
        }
        return prevChats;
      });

      // Asegurarse de que selectedChat esté actualizado
      setSelectedChat((prevChat) => ({
        ...prevChat,
        messages: [...prevChat.messages, newMessage],
      }));
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleAddClientName = async () => {
    if (!selectedChat) return;
    try {
      const payload = {
        userPhoneID: selectedChat.userPhoneID,
        cliente_tfn: selectedChat.cliente_tfn,
        nombre: clientName,
      };
      console.log("Sending client name:", payload);

      const response = await axios.post(addClientUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Client name added:", response.data);
      setShowAddNameModal(false);
      setClientName("");

      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.thread_id === selectedChat.thread_id
            ? { ...chat, nombre: clientName }
            : chat
        )
      );

      setSelectedChat((prevChat) => ({
        ...prevChat,
        nombre: clientName,
      }));
    } catch (error) {
      console.error("Error adding client name:", error);
    }
  };

  const handleToggleAutoResponder = async () => {
    if (!selectedChat) return;
    try {
      const payload = {
        userPhoneID: selectedChat.userPhoneID,
        thread_id: selectedChat.thread_id,
        activo: !selectedChat.activo,
      };
      console.log("Toggling auto responder:", payload);

      const response = await axios.post(toggleChatbotUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Auto responder toggled:", response.data);
      // Actualizar el estado del chat localmente
      setSelectedChat((prevChat) => ({
        ...prevChat,
        activo: !prevChat.activo, // Invertimos el estado actual
      }));

      // También actualizamos la lista de chats para reflejar el cambio
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.thread_id === selectedChat.thread_id
            ? { ...chat, activo: !chat.activo }
            : chat
        )
      );
    } catch (error) {
      console.error("Error toggling auto responder:", error);
    }
  };

  const handleShowClientStatus = () => {
    setShowClientStatusModal(true); // Mostrar modal con el estado de los clientes
  };

  // Función para enviar la sugerencia
  const handleSendSuggestion = async () => {
    try {
      const payload = {
        userPhoneID: localStorage.getItem("userPhoneID"),
        text: suggestionText,
      };
      await axios.post(suggestionsUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuggestionText("");
      setShowSuggestionForm(false);
      alert("¡Sugerencia enviada con éxito!");
    } catch (error) {
      console.error("Error sending suggestion:", error);
      alert("Error al enviar la sugerencia.");
    }
  };

  return (
    <div className="w-full h-full bg-gray-50 flex">
      <div className="w-1/5 h-full flex flex-col border-r border-gray-300">
        <ChatList
          chats={chats}
          onChatSelect={handleChatSelect}
          selectedChat={selectedChat}
          onOpenMetrics={() => setShowMetrics(true)}
          onShowClientStatus={handleShowClientStatus}
          onOpenSuggestions={() => setShowSuggestionForm(true)}
          onOpenSettings={() => setShowSettingsModal(true)}
        />
      </div>
      <div className="w-4/5 h-full flex flex-col">
        {selectedChat && (
          <ChatHeader
            selectedChat={selectedChat}
            onShowAddNameModal={() => setShowAddNameModal(true)}
            onToggleAutoResponder={handleToggleAutoResponder}
          />
        )}
        <div className="flex-grow overflow-y-auto">
          <MessageList messages={selectedChat?.messages || []} />
        </div>
        <SendForm onSend={handleSend} />
      </div>
      {showMetrics && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <Metrics metrics={metrics} onClose={() => setShowMetrics(false)} />
        </div>
      )}
      {showAddNameModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg mb-4">Agregar nombre del cliente</h2>
            <p className="mb-4">Teléfono: {selectedChat?.cliente_tfn}</p>
            <input
              type="text"
              placeholder="Nombre del cliente"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              className="p-2 border border-gray-400 rounded w-full mb-4"
            />
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white p-2 rounded mr-2"
                onClick={() => setShowAddNameModal(false)}
              >
                Cerrar
              </button>
              <button
                className="bg-bluePalette-dark text-white p-2 rounded"
                onClick={handleAddClientName}
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      )}
      {showSuggestionForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Enviar Sugerencia
            </h2>
            <textarea
              placeholder="Escribe tu sugerencia aquí..."
              value={suggestionText}
              onChange={(e) => setSuggestionText(e.target.value)}
              className="p-3 border border-gray-400 rounded w-full mb-4 resize-none"
              rows="6"
            />
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setShowSuggestionForm(false)}
              >
                Cerrar
              </button>
              <button
                className="bg-bluePalette-dark text-white px-4 py-2 rounded"
                onClick={handleSendSuggestion}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      )}
      {showSettingsModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg mb-4">Ajustes</h2>
            <button
              className="bg-bluePalette-dark text-white p-2 rounded w-full"
              onClick={() => {
                setShowSettingsModal(false);
                window.open(
                  "https://billing.stripe.com/p/login/test_00gaFagiw6DS7AI8ww",
                  "_blank"
                );
              }}
            >
              Gestionar Suscripción
            </button>

            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-500 text-white p-2 rounded"
                onClick={() => setShowSettingsModal(false)}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
      {showClientStatusModal && (
        <ClientStatusModal
          clients={chats}
          onClose={() => setShowClientStatusModal(false)}
        />
      )}
    </div>
  );
}

export default MainAppPage;
