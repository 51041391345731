// src/components/Chat/SendForm.js
import React, { useState } from "react";

function SendForm({ onSend }) {
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      onSend(message);
      setMessage("");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex p-4 bg-bluePalette-lightest border-t border-gray-400"
    >
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="flex-grow p-2 rounded border border-gray-400"
        placeholder="Escribe tu mensaje..."
      />
      <button
        type="submit"
        className="ml-4 bg-bluePalette-dark text-white p-2 rounded"
      >
        Enviar
      </button>
    </form>
  );
}

export default SendForm;
