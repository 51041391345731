import React from "react";

function GoogleCalendarConfigStep({ onGoogleAuth }) {
  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold mb-6">Autorizar Google Calendar</h2>
      <button
        onClick={onGoogleAuth}
        className="px-4 py-2 bg-bluePalette-dark text-white rounded hover:bg-bluePalette-darker"
      >
        Autorizar Google Calendar
      </button>
    </div>
  );
}

export default GoogleCalendarConfigStep;
