// src/components/Calendar/CalendarConfigStep.js
import React, { useState, useEffect } from "react";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";

function CalendarConfigStep({
  onNext,
  numCalendars,
  calendars: initialCalendars,
}) {
  const [calendars, setCalendars] = useState(
    initialCalendars.slice(0, numCalendars)
  );
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [bulkDays, setBulkDays] = useState([]);
  const [bulkTimeRanges, setBulkTimeRanges] = useState([
    { start: "09:00", end: "14:00" },
  ]);

  useEffect(() => {
    console.log("Initial calendars updated:", initialCalendars);
    setCalendars(initialCalendars.slice(0, numCalendars));
  }, [initialCalendars, numCalendars]);

  const timeStringToMinutes = (timeStr) => {
    if (!timeStr || !timeStr.includes(":")) return NaN;
    const [hours, minutes] = timeStr.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) return NaN;
    return hours * 60 + minutes;
  };

  // Funciones auxiliares para manejar errores
  const addError = (key, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: message,
    }));
  };

  const removeError = (key) => {
    setErrors((prevErrors) => {
      const { [key]: _, ...rest } = prevErrors;
      return rest;
    });
  };

  const handleCalendarChange = (index, field, value) => {
    console.log(`Changing calendar ${index} field ${field} to`, value);
    setCalendars((prevCalendars) => {
      const updatedCalendars = [...prevCalendars];
      updatedCalendars[index] = {
        ...updatedCalendars[index],
        [field]: field === "slotDuration" ? Number(value) : value,
      };

      // Re-validar los rangos de tiempo si cambia la duración del slot
      if (field === "slotDuration") {
        const updatedCalendar = updatedCalendars[index];
        const updatedDays = updatedCalendar.availableDays;

        for (const day in updatedDays) {
          updatedDays[day].forEach((range, rangeIndex) => {
            const durationInMinutes = getMinutesDifference(
              range.start,
              range.end
            );

            const key = `${index}-${day}-${rangeIndex}`;

            if (isNaN(durationInMinutes)) {
              addError(key, "La duración del rango no es válida.");
            } else if (durationInMinutes % Number(value) !== 0) {
              addError(
                key,
                `La duración del rango no es múltiplo de la duración del slot (${value} minutos).`
              );
            } else {
              removeError(key);
            }
          });
        }
      }

      return updatedCalendars;
    });
  };

  const handleBulkDayChange = (day, isChecked) => {
    if (isChecked) {
      setBulkDays((prevDays) => [...prevDays, day]);
    } else {
      setBulkDays((prevDays) => prevDays.filter((d) => d !== day));
    }
  };

  const handleBulkTimeRangeChange = (rangeIndex, value) => {
    setBulkTimeRanges((prevRanges) => {
      const updatedRanges = [...prevRanges];
      updatedRanges[rangeIndex] = { start: value[0], end: value[1] };
      return updatedRanges;
    });

    // Validar inmediatamente después del cambio
    handleBulkTimeRangeBlur(rangeIndex);
  };

  const handleBulkTimeRangeBlur = (rangeIndex) => {
    bulkDays.forEach((day) => {
      const calIndex = 0; // Asumiendo que estás aplicando al primer calendario, ajusta según tu lógica
      const key = `${calIndex}-${day}-bulk-${rangeIndex}`;
      const range = bulkTimeRanges[rangeIndex];

      if (!range.start || !range.end) {
        addError(key, "La hora de inicio y fin deben estar completas.");
        return;
      }

      const startMinutes = timeStringToMinutes(range.start);
      const endMinutes = timeStringToMinutes(range.end);

      if (isNaN(startMinutes) || isNaN(endMinutes)) {
        addError(key, "La hora de inicio y fin deben ser válidas.");
        return;
      }

      if (startMinutes >= endMinutes) {
        addError(key, "La hora de inicio debe ser anterior a la hora de fin.");
        return;
      }

      const updatedCalendar = calendars[calIndex];
      const durationInMinutes = endMinutes - startMinutes;

      if (durationInMinutes % updatedCalendar.slotDuration !== 0) {
        addError(
          key,
          `La duración del rango no es múltiplo de la duración del slot (${updatedCalendar.slotDuration} minutos).`
        );
      } else {
        removeError(key);
      }

      // Validar solapamiento con otros rangos existentes
      const existingRanges = updatedCalendar.availableDays[day] || [];
      const isOverlapping = existingRanges.some((existingRange) => {
        const existingStart = timeStringToMinutes(existingRange.start);
        const existingEnd = timeStringToMinutes(existingRange.end);
        return startMinutes < existingEnd && endMinutes > existingStart;
      });

      if (isOverlapping) {
        addError(
          key,
          `El rango ${range.start}-${range.end} se solapa con uno existente.`
        );
      } else {
        removeError(key);
      }
    });
  };

  const handleAddBulkTimeRange = () => {
    setBulkTimeRanges((prevRanges) => [
      ...prevRanges,
      { start: "09:00", end: "14:00" },
    ]);
  };

  const handleRemoveBulkTimeRange = (rangeIndex) => {
    setBulkTimeRanges((prevRanges) =>
      prevRanges.filter((_, index) => index !== rangeIndex)
    );

    // Eliminar errores relacionados
    bulkDays.forEach((day) => {
      const calIndex = 0; // Ajusta según tu lógica
      const key = `${calIndex}-${day}-bulk-${rangeIndex}`;
      removeError(key);
    });
  };

  const applyBulkTimeRange = (calIndex) => {
    console.log(
      `Applying bulk time ranges to days: ${bulkDays} in calendar ${calIndex}`
    );
    setCalendars((prevCalendars) => {
      const updatedCalendars = [...prevCalendars];
      const updatedCalendar = { ...updatedCalendars[calIndex] };
      let newErrors = { ...errors };

      bulkDays.forEach((day) => {
        const currentRanges = updatedCalendar.availableDays[day] || [];
        let dayRanges = [...currentRanges];

        bulkTimeRanges.forEach((newRange, bulkRangeIndex) => {
          const newStartMinutes = timeStringToMinutes(newRange.start);
          const newEndMinutes = timeStringToMinutes(newRange.end);

          const key = `${calIndex}-${day}-bulk-${bulkRangeIndex}`;

          // Validación de hora de inicio y fin
          if (isNaN(newStartMinutes) || isNaN(newEndMinutes)) {
            addError(key, "Las horas deben ser válidas.");
            return;
          }

          if (newStartMinutes >= newEndMinutes) {
            addError(
              key,
              `En ${day}, la hora de inicio debe ser anterior a la hora de fin.`
            );
            return;
          }

          // Validación de duración del slot
          const durationInMinutes = newEndMinutes - newStartMinutes;
          if (durationInMinutes % updatedCalendar.slotDuration !== 0) {
            addError(
              key,
              `En ${day}, la duración del rango ${newRange.start}-${newRange.end} no es múltiplo de la duración del slot (${updatedCalendar.slotDuration} minutos).`
            );
            return;
          }

          // Validación de solapamiento
          const isOverlapping = dayRanges.some((range) => {
            const rangeStartMinutes = timeStringToMinutes(range.start);
            const rangeEndMinutes = timeStringToMinutes(range.end);
            return (
              newStartMinutes < rangeEndMinutes &&
              newEndMinutes > rangeStartMinutes
            );
          });

          if (isOverlapping) {
            addError(
              key,
              `En ${day}, el rango ${newRange.start}-${newRange.end} se solapa con uno existente.`
            );
          } else {
            dayRanges.push(newRange);
            // Ordenar los rangos por hora de inicio
            dayRanges.sort(
              (a, b) =>
                timeStringToMinutes(a.start) - timeStringToMinutes(b.start)
            );
            removeError(key);
          }
        });

        updatedCalendar.availableDays = {
          ...updatedCalendar.availableDays,
          [day]: dayRanges,
        };
      });

      updatedCalendars[calIndex] = updatedCalendar;
      setErrors(newErrors);
      return updatedCalendars;
    });
  };

  const handleDayChange = (calIndex, day, isChecked) => {
    console.log(`Changing day ${day} for calendar ${calIndex} to`, isChecked);
    setCalendars((prevCalendars) => {
      const updatedCalendars = [...prevCalendars];
      const updatedCalendar = { ...updatedCalendars[calIndex] };

      if (isChecked) {
        updatedCalendar.availableDays = {
          ...updatedCalendar.availableDays,
          [day]:
            updatedCalendar.availableDays[day] || bulkTimeRanges.length > 0
              ? [...bulkTimeRanges]
              : [{ start: "09:00", end: "14:00" }],
        };

        // Validar los nuevos rangos añadidos
        (updatedCalendar.availableDays[day] || []).forEach(
          (range, rangeIndex) => {
            const key = `${calIndex}-${day}-${rangeIndex}`;
            const startMinutes = timeStringToMinutes(range.start);
            const endMinutes = timeStringToMinutes(range.end);
            const durationInMinutes = endMinutes - startMinutes;

            if (isNaN(startMinutes) || isNaN(endMinutes)) {
              addError(key, "Las horas deben ser válidas.");
            } else if (startMinutes >= endMinutes) {
              addError(
                key,
                "La hora de inicio debe ser anterior a la hora de fin."
              );
            } else if (durationInMinutes % updatedCalendar.slotDuration !== 0) {
              addError(
                key,
                `La duración del rango no es múltiplo de la duración del slot (${updatedCalendar.slotDuration} minutos).`
              );
            } else {
              removeError(key);
            }

            // Validar solapamiento
            const existingRanges = (
              updatedCalendar.availableDays[day] || []
            ).filter((_, idx) => idx !== rangeIndex);
            const isOverlapping = existingRanges.some((existingRange) => {
              const existingStart = timeStringToMinutes(existingRange.start);
              const existingEnd = timeStringToMinutes(existingRange.end);
              return startMinutes < existingEnd && endMinutes > existingStart;
            });

            if (isOverlapping) {
              addError(key, "El rango se solapa con uno existente.");
            } else {
              removeError(key);
            }
          }
        );
      } else {
        // Al desmarcar un día, eliminar todos los errores relacionados con ese día
        const dayErrorKeys = Object.keys(errors).filter((key) =>
          key.startsWith(`${calIndex}-${day}`)
        );
        dayErrorKeys.forEach((key) => removeError(key));

        const { [day]: removedDay, ...restDays } =
          updatedCalendar.availableDays;
        updatedCalendar.availableDays = restDays;
      }

      updatedCalendars[calIndex] = updatedCalendar;
      return updatedCalendars;
    });
  };

  const handleTimeRangeChange = (calIndex, day, rangeIndex, value) => {
    console.log(
      `Changing time range for ${day} in calendar ${calIndex} at index ${rangeIndex} to`,
      value
    );

    setCalendars((prevCalendars) => {
      const updatedCalendars = [...prevCalendars];
      const updatedCalendar = { ...updatedCalendars[calIndex] };
      const updatedDays = { ...updatedCalendar.availableDays };
      const updatedRanges = [...updatedDays[day]];

      updatedRanges[rangeIndex] = { start: value[0], end: value[1] };
      updatedDays[day] = updatedRanges;
      updatedCalendar.availableDays = updatedDays;
      updatedCalendars[calIndex] = updatedCalendar;

      return updatedCalendars;
    });

    // Validar después del cambio
    handleTimeRangeBlur(calIndex, day, rangeIndex);
  };

  const handleTimeRangeBlur = (calIndex, day, rangeIndex) => {
    console.log(
      `Validating time range for ${day} in calendar ${calIndex} at index ${rangeIndex}`
    );

    setCalendars((prevCalendars) => {
      const updatedCalendars = [...prevCalendars];
      const updatedCalendar = { ...updatedCalendars[calIndex] };
      const updatedDays = { ...updatedCalendar.availableDays };
      const updatedRanges = [...updatedDays[day]];

      const currentRange = updatedRanges[rangeIndex];
      const key = `${calIndex}-${day}-${rangeIndex}`;
      let hasError = false;

      if (!currentRange.start || !currentRange.end) {
        addError(key, "La hora de inicio y fin deben estar completas.");
        hasError = true;
      } else {
        const startMinutes = timeStringToMinutes(currentRange.start);
        const endMinutes = timeStringToMinutes(currentRange.end);

        if (isNaN(startMinutes) || isNaN(endMinutes)) {
          addError(key, "La hora de inicio y fin deben ser válidas.");
          hasError = true;
        } else if (startMinutes >= endMinutes) {
          addError(
            key,
            "La hora de inicio debe ser anterior a la hora de fin."
          );
          hasError = true;
        } else {
          const durationInMinutes = endMinutes - startMinutes;
          if (durationInMinutes % updatedCalendar.slotDuration !== 0) {
            addError(
              key,
              `La duración del rango no es múltiplo de la duración del slot (${updatedCalendar.slotDuration} minutos).`
            );
            hasError = true;
          } else {
            removeError(key);
          }

          // Validar solapamiento
          const isOverlapping = updatedRanges.some((range, index) => {
            if (index === rangeIndex) return false;
            const rangeStartMinutes = timeStringToMinutes(range.start);
            const rangeEndMinutes = timeStringToMinutes(range.end);
            return (
              startMinutes < rangeEndMinutes && endMinutes > rangeStartMinutes
            );
          });

          if (isOverlapping) {
            addError(key, "El nuevo rango se solapa con uno existente.");
            hasError = true;
          } else {
            if (!hasError) {
              removeError(key);
            }
          }
        }
      }

      return updatedCalendars;
    });
  };

  const getMinutesDifference = (startTime, endTime) => {
    const startTotalMinutes = timeStringToMinutes(startTime);
    const endTotalMinutes = timeStringToMinutes(endTime);

    if (isNaN(startTotalMinutes) || isNaN(endTotalMinutes)) return 0;

    return endTotalMinutes - startTotalMinutes;
  };

  const handleAddTimeRange = (calIndex, day) => {
    console.log(`Adding time range for ${day} in calendar ${calIndex}`);
    setCalendars((prevCalendars) => {
      const updatedCalendars = [...prevCalendars];
      const updatedCalendar = { ...updatedCalendars[calIndex] };
      const updatedDays = { ...updatedCalendar.availableDays };
      const currentRanges = updatedDays[day] || [];

      let newStart, newEnd;

      if (currentRanges.length > 0) {
        const lastRange = currentRanges[currentRanges.length - 1];
        newStart = lastRange.end;

        const [hours, minutes] = lastRange.end.split(":").map(Number);
        let newHour = hours + 1;
        if (newHour >= 24) newHour = 23;
        newStart = `${newHour.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        newEnd = `${newHour.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
      } else {
        newStart = "09:00";
        newEnd = "14:00";
      }

      const newStartMinutes = timeStringToMinutes(newStart);
      const newEndMinutes = timeStringToMinutes(newEnd);

      const key = `${calIndex}-${day}-${currentRanges.length}`;

      if (isNaN(newStartMinutes) || isNaN(newEndMinutes)) {
        addError(key, "Las horas deben ser válidas.");
        return prevCalendars;
      }

      if (newStartMinutes >= newEndMinutes) {
        addError(key, "La hora de inicio debe ser anterior a la hora de fin.");
        return prevCalendars;
      }

      const isOverlapping = currentRanges.some((range) => {
        const rangeStartMinutes = timeStringToMinutes(range.start);
        const rangeEndMinutes = timeStringToMinutes(range.end);
        return (
          newStartMinutes < rangeEndMinutes && newEndMinutes > rangeStartMinutes
        );
      });

      if (isOverlapping) {
        addError(key, "El nuevo rango se solapa con uno existente.");
        return prevCalendars;
      }

      const durationInMinutes = newEndMinutes - newStartMinutes;
      if (durationInMinutes % updatedCalendar.slotDuration !== 0) {
        addError(
          key,
          `La duración del rango no es múltiplo de la duración del slot (${updatedCalendar.slotDuration} minutos).`
        );
        return prevCalendars;
      }

      updatedDays[day] = [...currentRanges, { start: newStart, end: newEnd }];
      updatedCalendar.availableDays = updatedDays;
      updatedCalendars[calIndex] = updatedCalendar;
      removeError(key); // Si todo está bien, asegúrate de eliminar cualquier error residual
      return updatedCalendars;
    });
  };

  const handleRemoveTimeRange = (calIndex, day, rangeIndex) => {
    console.log(
      `Removing time range ${rangeIndex} for ${day} in calendar ${calIndex}`
    );
    setCalendars((prevCalendars) => {
      const updatedCalendars = [...prevCalendars];
      const updatedCalendar = { ...updatedCalendars[calIndex] };
      const updatedDays = { ...updatedCalendar.availableDays };
      const updatedRanges = updatedDays[day].filter(
        (_, index) => index !== rangeIndex
      );

      // Eliminar errores relacionados con este rango
      const key = `${calIndex}-${day}-${rangeIndex}`;
      removeError(key);

      if (updatedRanges.length === 0) {
        const { [day]: removedDay, ...restDays } = updatedDays;
        updatedCalendar.availableDays = restDays;
      } else {
        updatedDays[day] = updatedRanges;
        updatedCalendar.availableDays = updatedDays;
      }
      updatedCalendars[calIndex] = updatedCalendar;
      return updatedCalendars;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting calendars:", calendars);
    onNext(calendars);
  };

  const days = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md overflow-hidden max-h-[90vh]">
      <h2 className="text-2xl font-bold mb-6">Configuración de Calendarios</h2>
      {error && (
        <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">{error}</div>
      )}
      <form
        onSubmit={handleSubmit}
        className="overflow-y-auto max-h-[75vh] scrollbar-thin scrollbar-thumb-bluePalette-dark scrollbar-track-bluePalette-lightest"
      >
        {calendars.map((calendar, index) => (
          <div key={index} className="mb-8 p-4 border rounded">
            <h3 className="text-xl font-semibold mb-4">
              Calendario {index + 1}
            </h3>
            <div className="mb-4">
              <label className="block mb-2">Nombre del Calendario</label>
              <input
                type="text"
                value={calendar.name}
                onChange={(e) =>
                  handleCalendarChange(index, "name", e.target.value)
                }
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-bluePalette-dark"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Duración de Slots (minutos)</label>
              <input
                type="number"
                value={calendar.slotDuration}
                onChange={(e) =>
                  handleCalendarChange(index, "slotDuration", e.target.value)
                }
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-bluePalette-dark"
                min="15"
                step="15"
                required
              />
            </div>

            {/* Nueva sección para aplicar horarios a múltiples días con múltiples rangos */}
            <div className="mb-6 p-4 border rounded bg-gray-50">
              <h4 className="font-semibold mb-2">
                Aplicar horarios a múltiples días
              </h4>
              <div className="flex flex-wrap mb-2">
                {days.map((day) => (
                  <label
                    key={day}
                    className="inline-flex items-center mr-4 mb-2"
                  >
                    <input
                      type="checkbox"
                      checked={bulkDays.includes(day)}
                      onChange={(e) =>
                        handleBulkDayChange(day, e.target.checked)
                      }
                      className="mr-2"
                    />
                    {day}
                  </label>
                ))}
              </div>
              <div className="space-y-4">
                {bulkTimeRanges.map((range, rangeIndex) => (
                  <div key={rangeIndex} className="flex items-center space-x-2">
                    <div className="w-full max-w-xs">
                      <TimeRangePicker
                        onChange={(value) => {
                          if (value && value[0] && value[1]) {
                            handleBulkTimeRangeChange(rangeIndex, value);
                          } else {
                            const key = `bulk-${rangeIndex}`;
                            addError(
                              `bulk-${rangeIndex}`,
                              "Rango de tiempo inválido."
                            );
                          }
                        }}
                        onBlur={() => handleBulkTimeRangeBlur(rangeIndex)}
                        value={[range.start, range.end]}
                        disableClock={true}
                        clearIcon={null}
                        className="w-full"
                        format="HH:mm"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveBulkTimeRange(rangeIndex)}
                      className="px-2 py-1 bg-red-500 text-white text-sm rounded hover:bg-red-600"
                    >
                      Eliminar
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddBulkTimeRange}
                  className="mt-2 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Añadir Rango
                </button>
              </div>
              <button
                type="button"
                onClick={() => applyBulkTimeRange(index)}
                className="mt-4 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Aplicar horarios
              </button>
              {/* Mostrar errores de aplicación masiva */}
              {bulkDays.map((day) =>
                Object.keys(errors).some((key) =>
                  key.startsWith(`${index}-${day}-bulk`)
                ) ? (
                  <div key={day} className="text-red-500 text-sm mt-1">
                    {Object.entries(errors)
                      .filter(([key]) => key.startsWith(`${index}-${day}-bulk`))
                      .map(([key, message]) => (
                        <div key={key}>{message}</div>
                      ))}
                  </div>
                ) : null
              )}
            </div>

            <div>
              <h4 className="font-semibold mb-2">
                Días y Horarios Disponibles
              </h4>
              {days.map((day) => (
                <div key={day} className="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={!!calendar.availableDays[day]}
                      onChange={(e) =>
                        handleDayChange(index, day, e.target.checked)
                      }
                      className="mr-2"
                    />
                    {day}
                  </label>
                  {calendar.availableDays[day] && (
                    <div className="ml-6 mt-2 space-y-4">
                      {calendar.availableDays[day].map((range, rangeIndex) => (
                        <div
                          key={rangeIndex}
                          className="flex flex-col space-y-2"
                        >
                          <div className="w-full max-w-xs">
                            <TimeRangePicker
                              onChange={(value) => {
                                if (value && value[0] && value[1]) {
                                  handleTimeRangeChange(
                                    index,
                                    day,
                                    rangeIndex,
                                    value
                                  );
                                } else {
                                  const key = `${index}-${day}-${rangeIndex}`;
                                  addError(key, "Rango de tiempo inválido.");
                                }
                              }}
                              onBlur={() =>
                                handleTimeRangeBlur(index, day, rangeIndex)
                              }
                              value={[range.start, range.end]}
                              disableClock={true}
                              clearIcon={null}
                              className="w-full"
                              format="HH:mm"
                            />
                          </div>
                          {errors[`${index}-${day}-${rangeIndex}`] && (
                            <div className="text-red-500 text-sm">
                              {errors[`${index}-${day}-${rangeIndex}`]}
                            </div>
                          )}
                          <button
                            type="button"
                            onClick={() =>
                              handleRemoveTimeRange(index, day, rangeIndex)
                            }
                            className="w-full max-w-xs px-2 py-1 bg-red-500 text-white text-sm rounded hover:bg-red-600"
                          >
                            Eliminar
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => handleAddTimeRange(index, day)}
                        className="mt-2 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                      >
                        Añadir Rango
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="py-4 flex justify-end items-center bg-white">
          <button
            type="submit"
            className={`px-4 py-2 ${
              Object.keys(errors).length > 0
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-bluePalette-dark text-white"
            } rounded`}
            disabled={Object.keys(errors).length > 0}
          >
            Siguiente
          </button>
        </div>
      </form>
    </div>
  );
}

export default CalendarConfigStep;
