import React from "react";
import { FaUserEdit } from "react-icons/fa";

function ChatHeader({
  selectedChat,
  onShowAddNameModal,
  onToggleAutoResponder,
}) {
  return (
    <div className="p-4 bg-bluePalette-dark text-white flex justify-between items-center">
      <div className="flex items-center">
        <h1 className="text-lg">
          {selectedChat?.nombre || selectedChat?.cliente_tfn_sin_prefijo}
        </h1>
        <FaUserEdit
          className="cursor-pointer ml-2"
          onClick={onShowAddNameModal}
          size={24}
        />
      </div>
      <div className="flex items-center">
        <div className="relative group ml-4 inline-flex items-center cursor-pointer">
          <span className="w-4 h-4 bg-blue-500 text-white text-center rounded-full mr-2 flex items-center justify-center">
            ?
          </span>
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-48 p-2 bg-gray-700 text-white text-sm rounded hidden group-hover:block z-10">
            Activa o desactiva el auto-responder para este cliente.
          </div>
        </div>
        <label className="ml-2 inline-flex items-center cursor-pointer">
          <span className="mr-2 text-sm">Auto-Responder</span>
          <div className="relative">
            <input
              type="checkbox"
              checked={selectedChat?.activo || false}
              onChange={onToggleAutoResponder}
              className="sr-only"
            />
            <div
              className={`w-10 h-4 ${
                selectedChat?.activo ? "bg-green-500" : "bg-red-500"
              } rounded-full shadow-inner`}
            ></div>
            <div
              className={`dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${
                selectedChat?.activo ? "transform translate-x-full" : ""
              }`}
            ></div>
          </div>
        </label>
      </div>
    </div>
  );
}

export default ChatHeader;
