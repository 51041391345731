// src/components/Calendar/Config.js
import React, { useState, useEffect } from "react";
import CalendarConfigStep from "./CalendarConfigStep";
import CalendarPreviewStep from "./CalendarPreviewStep"; // Importa el nuevo componente
import GoogleCalendarConfigStep from "./GoogleCalendarConfigStep";
import LoadingScreen from "../LoadingScreen";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Config() {
  const [step, setStep] = useState(1);
  const [calendarConfig, setCalendarConfig] = useState([]);
  const [googleTokens, setGoogleTokens] = useState(null);
  const [authCodeProcessed, setAuthCodeProcessed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userPhoneID = localStorage.getItem("userPhoneID");
  const token = localStorage.getItem("jwtToken");
  const numCalendars = parseInt(localStorage.getItem("numCalendars"), 10);
  const navigate = useNavigate();

  const apiGatewayUrl = process.env.REACT_APP_API_GATEWAY_URL;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;

  console.log("API Gateway URL:", apiGatewayUrl);
  console.log("Google Client ID:", googleClientId);
  console.log("Redirect URI:", redirectUri);

  useEffect(() => {
    const savedCalendarConfig = localStorage.getItem("calendarConfig");
    if (savedCalendarConfig) {
      setCalendarConfig(JSON.parse(savedCalendarConfig));
    } else {
      const initialCalendarConfig = Array(numCalendars).fill({
        name: "",
        slotDuration: 30,
        availableDays: {},
      });
      setCalendarConfig(initialCalendarConfig);
    }
  }, [numCalendars]);

  const handleCalendarConfigNext = (calendars) => {
    console.log("Calendars received from CalendarConfigStep:", calendars);
    setCalendarConfig(calendars);
    localStorage.setItem("calendarConfig", JSON.stringify(calendars));
    setStep(2); // Ahora pasa al paso de previsualización
  };

  const handleGoogleAuthCode = async (authCode) => {
    if (authCodeProcessed) return;

    setAuthCodeProcessed(true);
    try {
      const response = await axios.get(
        `${apiGatewayUrl}/handle-google-auth?code=${authCode}`
      );
      const { accessToken, refreshToken } = response.data;
      console.log("Google tokens:", accessToken, refreshToken);
      setGoogleTokens({ accessToken, refreshToken });
      setStep(4);

      // Limpia la URL después de procesar el código
      window.history.replaceState({}, document.title, "/config");
    } catch (error) {
      console.error("Error fetching Google tokens:", error);
      setStep(3);
    }
  };

  const handleSubmitConfig = async () => {
    setIsLoading(true); // Inicia la pantalla de carga
    const fullConfig = {
      userPhoneID,
      googleTokens,
      calendarConfig,
    };

    try {
      const response = await axios.post(`${apiGatewayUrl}/config`, fullConfig, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Configuration saved:", response.data);
      if (response.status === 200) {
        setSuccess(true);
        localStorage.removeItem("calendarConfig"); // Limpiar localStorage después de guardar
        navigate("/app");
      }
    } catch (error) {
      console.error("Error saving configuration:", error);
    } finally {
      setIsLoading(false); // Finaliza la pantalla de carga
    }
  };

  const handleGoogleAuth = () => {
    console.log("prompt=consent");
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&client_id=${googleClientId}&prompt=consent`;
    window.location.href = authUrl;
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const authCode = query.get("code");

    if (authCode && !authCodeProcessed) {
      setStep(4);
      handleGoogleAuthCode(authCode);
    }
  }, [authCodeProcessed]);

  useEffect(() => {
    if (step === 4 && googleTokens) {
      handleSubmitConfig();
    }
  }, [step, googleTokens]);

  const handleRedirectToApp = () => {
    window.location.href = "/app";
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto w-full">
        <div className="absolute inset-0 bg-gradient-to-r from-bluePalette-light to-bluePalette-darker shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20 max-h-[90vh] overflow-hidden scrollbar-thin scrollbar-thumb-bluePalette-dark scrollbar-track-bluePalette-lightest">
          {isLoading ? (
            <LoadingScreen />
          ) : success ? (
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-6">
                ¡Configuración completada!
              </h2>
              <button
                onClick={handleRedirectToApp}
                className="px-4 py-2 bg-bluePalette-dark text-white rounded hover:bg-bluePalette-darker"
              >
                Ir a la aplicación
              </button>
            </div>
          ) : (
            <>
              {step === 1 && (
                <CalendarConfigStep
                  onNext={handleCalendarConfigNext}
                  calendars={calendarConfig}
                  numCalendars={numCalendars}
                />
              )}
              {step === 2 && (
                <CalendarPreviewStep
                  calendars={calendarConfig}
                  onConfirm={() => setStep(3)} // Avanza al paso de autorización de Google
                  onEdit={() => setStep(1)} // Vuelve al paso de configuración si se quiere editar
                />
              )}
              {step === 3 && (
                <GoogleCalendarConfigStep onGoogleAuth={handleGoogleAuth} />
              )}
              {step === 4 && <LoadingScreen />}
              {step === 5 && (
                <div className="text-center">
                  <h2 className="text-2xl font-bold mb-6">
                    Confirmar Configuración
                  </h2>
                  <button
                    onClick={handleSubmitConfig}
                    className="px-4 py-2 bg-bluePalette-dark text-white rounded hover:bg-bluePalette-darker"
                  >
                    Guardar Configuración
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Config;
